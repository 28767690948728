<template>
  <div v-if="yieldSettingsPlayground">
    <div v-if="minimizeSettings">
      <article class="message is-small">
        <div class="message-body">
          Pax: {{ settings.minSeats }} - {{ settings.maxSeats }} Day parts:
          {{ settings.nrOfDayParts }}
        </div>
      </article>
    </div>

    <div v-if="!minimizeSettings">
      <div class="has-text-right">
        <a
          @click="showAdvancedSettings = !showAdvancedSettings"
          class="is-size-7"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'cog']" />
          </span>
          <span>Advanced settings</span>
        </a>
      </div>

      <label class="has-text-weight-bold">Pax</label>
      <div class="columns">
        <div class="column" v-if="yieldSettingsPlayground.MeetingtypeId === 1">
          <input
            type="text"
            class="input is-small"
            v-model.number="settings.minSeats"
          />
        </div>
        <div class="column" v-if="yieldSettingsPlayground.MeetingtypeId === 1">
          <input
            type="text"
            class="input is-small"
            v-model.number="settings.maxSeats"
          />
        </div>

        <div class="column">
          <div class="select is-small has-margin-right">
            <select v-model="settings.nrOfDayParts">
              <option
                v-for="(dayPart, index) in nrOfDayParts"
                :key="index"
                :value="dayPart"
                :selected="settings.nrOfDayParts === dayPart"
              >
                {{ dayPart }}
              </option>
            </select>
          </div>
          <span
            v-if="yieldSettingsPlayground.CalculationType === 'PerHourPerSeat'"
            class="is-size-7"
            >hours</span
          >
          <span
            v-if="yieldSettingsPlayground.CalculationType !== 'PerHourPerSeat'"
            class="is-size-7"
            >day parts</span
          >
        </div>
      </div>

      <div v-if="showAdvancedSettings">
        <label class="has-text-weight-bold"
          >Location utilization percentages</label
        >
        <table class="table is-striped is-narrow">
          <tbody>
            <tr v-for="(percentage, index) in sortedPercentages" :key="index">
              <td width="25">
                <a @click="deletePercentage(index)" class="is-size-7">
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </a>
              </td>
              <td width="100">
                <div class="field has-addons">
                  <p class="control">
                    <input
                      type="text"
                      @change="updatePercentage($event.target.value, index)"
                      :value="percentage"
                      class="input is-small"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-small is-static">%</a>
                  </p>
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
              <td>
                <input
                  type="text"
                  v-on:keyup.13="addPercentage"
                  v-model.number="newPercentage"
                  class="input is-small"
                />
              </td>
              <td>
                <a @click="addPercentage">
                  <span class="icon has-text-success">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </span>
                </a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <a @click="showResults" class="button is-primary">Show results</a>
    </div>

    <div class="has-text-centered">
      <a
        @click="minimizeSettings = !minimizeSettings"
        class="button is-small is-rounded"
      >
        <span v-if="!minimizeSettings" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-up']" />
        </span>
        <span v-if="minimizeSettings" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    minSeats: {
      type: Number,
      default: 1,
    },

    maxSeats: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      minimizeSettings: false,
      newPercentage: 0,
      settings: {
        minSeats: this.minSeats,
        maxSeats: this.maxSeats,
        nrOfDayParts: 2,
        percentages: [0, 25, 50, 75, 100],
      },
      showAdvancedSettings: false,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),

    sortedPercentages() {
      let percentages = this.settings.percentages

      percentages.sort((a, b) => {
        return a - b
      })

      return percentages
    },

    nrOfDayParts() {
      let nrOfDayParts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

      if (this.yieldSettingsPlayground.CalculationType !== 'PerHourPerSeat') {
        nrOfDayParts = [1, 2, 3]
      }

      return nrOfDayParts
    },
  },

  created() {
    if (
      this.yieldSettingsPlayground &&
      this.yieldSettingsPlayground.MeetingtypeId === 2
    ) {
      this.settings.minSeats = 1
      this.settings.maxSeats = 1
    }

    this.setDayParts()
  },

  methods: {
    setDayParts() {
      if (this.yieldSettingsPlayground.CalculationType === 'PerHourPerSeat') {
        this.settings.nrOfDayParts = 8
      } else {
        this.settings.nrOfDayParts = 2
      }
    },

    addPercentage() {
      if (
        this.newPercentage <= 100 &&
        !this.isPercentageAlreadyAdded(Number(this.newPercentage))
      ) {
        this.settings.percentages.push(Number(this.newPercentage))
      }
      this.newPercentage = 0
    },

    isPercentageAlreadyAdded(percentage) {
      return (
        this.settings.percentages.findIndex((p) => p === this.newPercentage) >
        -1
      )
    },

    updatePercentage(value, index) {
      this.$set(this.settings.percentages, index, Number(value))
    },

    deletePercentage(index) {
      this.settings.percentages.splice(index, 1)
    },

    showResults() {
      this.$emit('showResults', this.settings)
    },
  },
}
</script>
